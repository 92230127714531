import Vue from 'vue';
import vuetify from './plugins/vuetify';

import './plugins/axios';
import api from './plugins/api';

import moment from 'moment';

import './filters';

import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';
import './vee-validate';

import VueMoment from 'vue-moment';
import VueTimers from 'vue-timers';
import VCurrencyField from 'v-currency-field';
import { VTextField } from 'vuetify/lib';
import VueTheMask from 'vue-the-mask';

import VueApexCharts from 'vue-apexcharts';

import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';

if(process.env.VUE_APP_ENVIRONMENT !== 'local') {
    Sentry.init({
        dsn: 'https://ce0221a982584e77a079b695b24553aa@sentry.io/1876878',
        environment: process.env.VUE_APP_ENVIRONMENT,
        integrations: [new Integrations.Vue({ Vue, attachProps: true, logErrors: true })]
    });
}

Vue.config.productionTip = false;

require('moment/locale/es');
moment.locale('es');

Vue.use(VueMoment, {
    moment
});

Vue.use(VueTheMask);
Vue.use(VueTimers);
Vue.use(api);

Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts);

Vue.component('v-text-field', VTextField);
Vue.use(VCurrencyField, {
    locale: 'es-AR',
    decimalLength: 2,
    autoDecimalMode: false,
    min: null,
    max: null,
    defaultValue: 0
});

new Vue({
    vuetify,
    store,
    router,
    i18n,
    render: h => h(App),
    data: { isLoading: true }
}).$mount('#app');
