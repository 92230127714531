import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import '@mdi/font/css/materialdesignicons.css';

import es from 'vuetify/es5/locale/es';

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
        locales: { es },
        current: 'es'
    },
    icons: {
        iconfont: 'mdiSvg'
    },
    theme: {
        options: {
            customProperties: true
        },

        themes: {
            light: {
                primary: '#4caf50', // 398bf7
                secondary: '#4caf50',
                accent: '#cddc39',
                error: '#f44336',
                warning: '#ff9800',
                info: '#607d8b',
                success: '#009688',
                // background: '#ffffff'
            },
            dark: {
                primary: '#4caf50',
                secondary: '#4caf50',
                accent: '#cddc39',
                error: '#f44336',
                warning: '#ff9800',
                info: '#607d8b',
                success: '#009688',
                // background: '#2b2e44'
            }
        }
    }
});
