import Vue from 'vue';

export default {
    namespaced: true,

    state: {
        isLoading: false,

        items: [],
        total: 0,

        movements: []
    },

    getters: {
        isLoading: (state) => () => {
            return state.isLoading;
        },

        getAll: (state) => () => {
            return state.items;
        },

        getById: (state) => (id) => {
            return state.items.find(item => item.id === id);
        },

        getTotal: (state) => () => {
            return state.total;
        },

        getAllMovements: (state) => () => {
            return state.movements;
        }
    },

    mutations: {
        set(state, { items, total }) {
            state.total = total;

            for(let item of items) {
                let found = state.items.find(c => c.id === item.id);

                if(found) {
                    for(let key of Object.keys(item)) {
                        Vue.set(found, key, item[key]);
                    }
                }
                else {
                    state.items.push(item);
                }
            }
        },

        setMovements(state, { movements, total }) {
            state.movements = movements;
            state.totalMovements = total;
        }
    },

    actions: {
        async fetchMovements({ commit, state }, filters) {
            state.isLoading = true;

            try {
                let result = await this._vm.$api.get('/api/production/inventory_movements', filters);

                commit('setMovements', {
                    movements: result.records,
                    total: result.count
                });
            }
            finally {
                state.isLoading = false;
            }
        },

        async fetchAll({ commit, state }, filters) {
            state.isLoading = true;

            try {
                let result = await this._vm.$api.get('/api/production/inventory', filters);

                commit('set', {
                    items: result.records,
                    total: result.count
                });

                return {
                    total: result.count,
                    ids: result.records.map(item => item.id)
                };
            }
            finally {
                state.isLoading = false;
            }
        },

        async create({ commit, state }, { data }) {
            state.isLoading = true;

            try {
                let result = await this._vm.$api.post('/api/production/inventory', data);
                commit('set', { items: [ result ] });

                return {
                    id: result.id
                };
            }
            finally {
                state.isLoading = false;
            }
        },

        async patch({ commit, state }, { inventory, data }) {
            state.isLoading = true;

            try {
                let result = await this._vm.$api.patch('/api/production/inventory/' + inventory.id, data);
                commit('set', { items: [ result ] });

                return {
                    id: result.id
                };
            }
            finally {
                state.isLoading = false;
            }
        },

        async ingress({ commit, state }, data) {
            state.isLoading = true;

            try {
                let result = await this._vm.$api.post('/api/production/inventory_movements', data);
            }
            finally {
                state.isLoading = false;
            }
        },

        async egress({ commit, state }, data) {
            state.isLoading = true;

            try {
                let result = await this._vm.$api.post('/api/production/inventory_movements', data);
            }
            finally {
                state.isLoading = false;
            }
        }
    }
};
