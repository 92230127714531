import Vue from 'vue';

export default {
    namespaced: true,

    state: {
        isLoading: false,
        holidays: []
    },

    getters: {
        isLoading: (state) => () => {
            return state.isLoading;
        },

        getAll: (state) => () => {
            return state.holidays;
        },

        getById: (state) => (id) => {
            return state.holidays.find(item => item.id === id);
        }
    },

    mutations: {
        set: (state, { holidays }) => {
            for(let holiday of holidays) {
                let found = state.holidays.find(v => v.id === holiday.id);

                if(found) {
                    for(let key of Object.keys(holiday)) {
                        Vue.set(found, key, holiday[key]);
                    }
                }
                else {
                    state.holidays.push(holiday);
                }
            }
        },

        remove: (state, { holiday }) => {
            let idx = state.holidays.findIndex(v => holiday.id);
            state.holidays.splice(idx, 1);
        }
    },

    actions: {
        async fetchAll({ commit, state }) {
            state.isLoading = true;

            try {
                let result = await this._vm.$api.get('/api/holidays');
                commit('set', { holidays: result });
            }
            finally {
                state.isLoading = false;
            }
        },

        async create({ commit, state }, data) {
            state.isLoading = true;

            try {
                let result = await this._vm.$api.post('/api/holidays', data);
                commit('set', { holidays: [ result ] });

                return {
                    id: result.id
                };
            }
            finally {
                state.isLoading = false;
            }
        },

        async delete({ commit, state }, { holiday }) {
            state.isLoading = true;

            try {
                let result = await this._vm.$api.delete('/api/holidays/' + holiday.id);
                commit('remove', { holiday: result });

                return {
                    id: result.id
                };
            }
            finally {
                state.isLoading = false;
            }
        }
    }
};
