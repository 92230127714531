import Vue from 'vue';

export default {
    namespaced: true,

    state: {
        isLoading: false,
        vehicles: []
    },

    getters: {
        isLoading: (state) => () => {
            return state.isLoading;
        },

        getAll: (state) => () => {
            return state.vehicles;
        },

        getById: (state) => (id) => {
            return state.vehicles.find(item => item.id === id);
        }
    },

    mutations: {
        set: (state, { vehicles }) => {
            for(let vehicle of vehicles) {
                let found = state.vehicles.find(v => v.id === vehicle.id);

                if(found) {
                    for(let key of Object.keys(vehicle)) {
                        Vue.set(found, key, vehicle[key]);
                    }
                }
                else {
                    state.vehicles.push(vehicle);
                }
            }
        },

        setSchedule: (state, { vehicle, schedule }) => {
            Vue.set(vehicle, 'schedule', schedule);
        },

        addSchedule: (state, { vehicle, schedule }) => {
            vehicle.schedule.push(schedule);
        },

        removeSchedule: (state, { vehicle, schedule }) => {
            const idx = vehicle.schedule.indexOf(schedule);
            if(idx !== -1) {
                vehicle.schedule.splice(idx, 1);
            }
        }
    },

    actions: {
        async fetchAll({ commit, state }) {
            state.isLoading = true;

            try {
                let result = await this._vm.$api.get('/api/vehicles');
                commit('set', { vehicles: result });
            }
            finally {
                state.isLoading = false;
            }
        },

        async fetchOne({ commit, state }, { id }) {
            state.isLoading = true;

            try {
                let result = await this._vm.$api.get('/api/vehicles/' + id);
                commit('set', { vehicles: [ result ] });
            }
            finally {
                state.isLoading = false;
            }
        },

        async fetchSchedule({ commit, state }, { vehicle, filters }) {
            state.isLoading = true;

            try {
                let result = await this._vm.$api.get('/api/vehicles/' + vehicle.id + '/schedule', filters);
                commit('setSchedule', {
                    vehicle: vehicle,
                    schedule: result
                });
            }
            finally {
                state.isLoading = false;
            }
        },

        async create({ commit, state }, data) {

        },

        async patch({ commit, state }, { vehicle, data }) {
            state.isLoading = true;

            try {
                let result = await this._vm.$api.patch('/api/vehicles/' + vehicle.id, data);
                commit('set', { vehicles: [ result ] });

                return {
                    id: result.id
                };
            }
            finally {
                state.isLoading = false;
            }
        },

        async addSchedule({ commit, state }, { vehicle, data }) {
            state.isLoading = true;

            try {
                let result = await this._vm.$api.post('/api/vehicles/' + vehicle.id + '/schedule', data);
                commit('addSchedule', {
                    vehicle: vehicle,
                    schedule: result
                });

                return {
                    id: result.id
                };
            }
            finally {
                state.isLoading = false;
            }
        },

        async deleteSchedule({ commit, state }, { vehicle, schedule }) {
            state.isLoading = true;

            try {
                let result = await this._vm.$api.delete('/api/vehicles/' + vehicle.id + '/schedule/' + schedule.id);
                commit('removeSchedule', {
                    vehicle: vehicle,
                    schedule: schedule
                });

                return {
                    id: result.id
                };
            }
            finally {
                state.isLoading = false;
            }
        }
    }
};
