import Vue from 'vue';

export default {
    namespaced: true,

    state: {
        isLoading: false,

        items: [],
        total: 0
    },

    getters: {
        isLoading: (state) => () => {
            return state.isLoading;
        },

        getAll: (state) => () => {
            return state.items;
        },

        getById: (state) => (id) => {
            return state.items.find(item => item.id === id);
        },

        getTotal: (state) => () => {
            return state.total;
        }
    },

    mutations: {
        set(state, { items }) {
            for(let item of items) {
                let found = state.items.find(c => c.id === item.id);

                if(found) {
                    for(let key of Object.keys(item)) {
                        Vue.set(found, key, item[key]);
                    }
                }
                else {
                    state.items.push(item);
                }
            }
        }
    },

    actions: {
        async fetchAll({ commit, state }, filters) {
            state.isLoading = true;

            try {
                let result = await this._vm.$api.get('/api/production/processes', filters);

                commit('set', { items: result.records });
                state.total = result.count;

                return {
                    total: result.count,
                    ids: result.records.map(item => item.id)
                };
            }
            finally {
                state.isLoading = false;
            }
        },

        async create({ commit, state }, { data }) {
            state.isLoading = true;

            try {
                let result = await this._vm.$api.post('/api/production/processes', data);
                commit('set', { items: [ result ] });

                return {
                    id: result.id
                };
            }
            finally {
                state.isLoading = false;
            }
        },

        async patch({ commit, state }, { inventory, data }) {
            state.isLoading = true;

            try {
                let result = await this._vm.$api.patch('/api/production/processes/' + inventory.id, data);
                commit('set', { items: [ result ] });

                return {
                    id: result.id
                };
            }
            finally {
                state.isLoading = false;
            }
        },

        async process({ commit, state }, data) {
            state.isLoading = true;

            try {
                let result = await this._vm.$api.post('/api/production/process', data);
            }
            finally {
                state.isLoading = false;
            }
        }
    }
};
