export default {
    namespaced: true,

    state: {
        statusMessage: null
    },

    getters: {
        getStatusMessage: (state) => () => {
            return state.statusMessage;
        }
    },

    mutations: {
        setStatusMessage(state, message) {
            state.statusMessage = message;
        }
    },

    actions: {
        setStatusMessage({ commit }, message) {
            commit('setStatusMessage', message);
        }
    }
};
