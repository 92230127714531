import Vue from 'vue';

export default {
    namespaced: true,

    state: {
        isLoading: false,
        categories: []
    },

    getters: {
        isLoading: (state) => () => {
            return state.isLoading;
        },

        getAll: (state) => () => {
            return state.categories;
        }
    },

    mutations: {
        set: (state, { categories }) => {
            for(let category of categories) {
                let found = state.categories.find(v => v.id === category.id);

                if(found) {
                    for(let key of Object.keys(category)) {
                        Vue.set(found, key, category[key]);
                    }
                }
                else {
                    state.categories.push(category);
                }
            }
        },

        remove: (state, { category }) => {
            let idx = state.categories.findIndex(v => category.id);
            state.categories.splice(idx, 1);
        }
    },

    actions: {
        async fetchAll({ commit, state }) {
            state.isLoading = true;

            try {
                let result = await this._vm.$api.get('/api/categories');
                commit('set', { categories: result });
            }
            finally {
                state.isLoading = false;
            }
        },

        async create({ commit, state }, data) {
            state.isLoading = true;

            try {
                let result = await this._vm.$api.post('/api/categories', data);
                commit('set', { categories: [ result ] });

                return {
                    id: result.id
                };
            }
            finally {
                state.isLoading = false;
            }
        },

        async update({ commit, state }, { category, data }) {
            state.isLoading = true;

            try {
                let result = await this._vm.$api.patch('/api/categories/' + category.id, data);
                commit('set', { categories: [ result ] });

                return {
                    id: result.id
                };
            }
            finally {
                state.isLoading = false;
            }
        },

        async delete({ commit, state }, { category }) {
            state.isLoading = true;

            try {
                let result = await this._vm.$api.delete('/api/categories/' + category.id);
                commit('remove', { category: category });

                return {
                    id: result.id
                };
            }
            finally {
                state.isLoading = false;
            }
        }
    }
};
