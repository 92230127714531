import Vue from 'vue';
import Vuex from 'vuex';

import app from './store/App';
import cart from './store/Cart';
import cashAccounts from './store/CashAccounts';
import customer from './store/Customer';
import claims from './store/Claims';
import deliveryRoutes from './store/DeliveryRoutes';
import deliveryZones from './store/DeliveryZones';
import employees from './store/Employees';
import vehicles from './store/Vehicles';
import order from './store/Order';
import parameters from './store/Parameters';
import products from './store/Products';
import providers from './store/Providers';
import stock from './store/Stock';
import user from './store/User';
import harvest from './store/Harvest';
import payments from './store/Payments';
import shipments from './store/Shipments';
import vehicleFeatures from './store/VehicleFeatures';
import holidays from './store/Holidays';
import productCategory from './store/ProductCategory';
import inventory from './store/Inventory';
import process from './store/Process';

Vue.use(Vuex);

const objectComparePlugin = store => {
    store.objectCompare = function(a, b) {
        return JSON.stringify(a) === JSON.stringify(b);
    };

    store.objectClone = function(a) {
        return JSON.parse(JSON.stringify(a));
    };
};

export default new Vuex.Store({
    modules: {
        app,
        cart,
        cashAccounts,
        customer,
        claims,
        deliveryRoutes,
        deliveryZones,
        employees,
        vehicles,
        order,
        parameters,
        products,
        providers,
        stock,
        user,
        harvest,
        payments,
        shipments,
        vehicleFeatures,
        holidays,
        productCategory,
        inventory,
        process
    },
    plugins: [
        objectComparePlugin
    ]
});
