import Vue from 'vue';

let initialState = {
    user: null,
    notifications: []
};

try {
    const user = JSON.parse(localStorage.getItem('user'));
    initialState.user = user;
}
catch {

}

export default {
    namespaced: true,

    state: initialState,

    getters: {
        isAuthenticated: (state) => !!state.user && !!state.user.token,

        get: (state) => {
            return state.user;
        },

        getNotifications: (state) => {
            return state.notifications;
        },

        hasRole: (state) => (role) => {
          return state.user && state.user.roles && state.user.roles.includes(role);
        },

        getToken: (state) => () => {
            return state.user ? state.user.token : null;
        }
    },

    mutations: {
        set(state, user) {
            state.user = user;
            Vue.set(state, 'user', user);

            if(state.user && !state.user.notifications) {
                state.user.notifications = [];
            }
        },

        setNotifications(state, { notifications }) {
            if(!notifications) {
                return;
            }

            Vue.set(state, 'notifications', [ ...notifications ]);
        }
    },

    actions: {
        async login({ commit, state }, { username, password }) {
            try {
                let response = await this._vm.$api.login(username, password);
                this.checked = true;

                const user = response;
                localStorage.setItem('user', JSON.stringify(user));

                commit('set', user);
            }
            catch (err) {
                localStorage.removeItem('user'); // if the request fails, remove any possible user token if possible

                throw err;
            }
        },

        logout({ commit }) {
            localStorage.removeItem('user');
            commit('set', null);
        },

        async fetchNotifications({ state, commit }) {
            let result = await this._vm.$api.getUserNotifications(state.user.id);

            commit('setNotifications', { notifications: result });
        },

        async markNotificationsAsRead({ state, commit }, notifications) {
            if(notifications && notifications.length > 0) {
                let result = await this._vm.$api.patchUserNotificationsRead(state.user.id, notifications.map(n => n.id));

                commit('setNotifications', { notifications: result });
            }
        }
    }
};
