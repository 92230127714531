import Vue from 'vue';

export default {
    namespaced: true,

    state: {
        isLoading: false,
        isSaving: false,

        payments: [],
        total: 0,
        stats: null
    },

    getters: {
        isLoading: (state) => () => {
            return state.isLoading;
        },

        isSaving: (state) => () => {
            return state.isSaving;
        },

        getAll: (state) => () => {
            return state.payments;
        },

        getTotal: (state) => () => {
            return state.total;
        },

        getStats: (state) => () => {
            return state.stats;
        }
    },

    mutations: {
        set(state, { total, payments }) {
            Vue.set(state, 'total', total);
            Vue.set(state, 'payments', payments);
        },

        add(state, { total, payments }) {
            state.total = total;

            if(state.payments) {
                for(let p of payments) {
                    let found = state.payments.find(pp => pp.id === p.id);
                    if(!found) {
                        state.payments.push(p);
                    }
                }
            }
            else {
                state.payments = payments;
            }
        },

        setStats(state, { stats }) {
            state.stats = stats;
        }
    },

    actions: {
        clear({ commit, state }) {
            commit('set', { total: 0, payments: [] });
            commit('setStats', { stats: null });
        },

        async fetchStats({ commit, state }, filters) {
            let result = await this._vm.$api.get('/api/payments/stats', filters);
            commit('setStats', { stats: result });
        },

        async fetchAll({ commit, state }, filters) {
            state.isLoading = true;

            let result = await this._vm.$api.get('/api/payments', filters);
            commit('add', {
                total: result.count,
                payments: result.records
            });

            state.isLoading = false;
        },

        async return({ commit, state }, { payment }) {
            state.isLoading = true;

            await this._vm.$api.delete('/api/payments/' + payment.id);

            state.isLoading = false;
        }         
    }
};
