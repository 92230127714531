import Vue from 'vue';
import { normalizeRelations, resolveRelations } from './helpers';

export default {
    namespaced: true,

    state: {
        loadingCounter: 0,
        isLoading: false,

        ids: [],
        orders: []
    },

    getters: {
        isLoading: (state) => () => {
            return state.loadingCounter > 0;
        },

        getAll: (state, getters) => () => {
            return state.ids.map(id => getters.getById(id));
        },

        getById: (state, getters, rootState, rootGetters) => (id) => {
            let order = state.orders.find(item => item.id === id);
            return order;
        }
    },

    mutations: {
        set(state, { orders }) {
            for(let order of orders) {
                let found = state.orders.find(o => o.id === order.id);

                if(found) {
                    for(let key of Object.keys(order)) {
                        Vue.set(found, key, order[key]);
                    }
                }
                else {
                    // initialize the extra arrays to avoid the watcher being called when doing Vue.set()
                    order.payments = [];
                    order.shipments = [];
                    order.comments = [];
                    order.activity = [];
                    order.shipping = {};

                    state.orders.push(order);
                }
            }
        },

        addPayment(state, { order, payment }) {
            let found = state.orders.find(o => o.id === order.id);

            if(found && payment) {
                found.payments.push(payment);
            }
        },

        setPayments(state, { order, payments }) {
            let found = state.orders.find(o => o.id === order.id);

            if(found && payments) {
                found.payments = payments;
            }
        },

        setShipments(state, { order, shipments }) {
            let found = state.orders.find(o => o.id === order.id);

            if(found && shipments) {
                found.shipments = shipments;
            }
        },

        setShipping(state, { order, shipment }) {
            let found = state.orders.find(o => o.id === order.id);

            if(found && shipment) {
                Vue.set(found, 'shipping', shipment);
            }
        },

        addComment(state, { order, comment }) {
            let found = state.orders.find(o => o.id === order.id);

            if(found && comment) {
                found.comments.push(comment);

                Vue.set(found, 'commentCount', found.commentCount + 1);
            }
        },

        setComments(state, { order, comments }) {
            let found = state.orders.find(o => o.id === order.id);

            if(found && comments) {
                Vue.set(found, 'comments', comments);
            }
        },

        setActivity(state, { order, activity }) {
            let found = state.orders.find(o => o.id === order.id);

            if(found && activity) {
                Vue.set(found, 'activity', activity);
            }
        }
    },

    actions: {
        async fetchAll({ commit, state }, config) {
            let result = null;

            state.loadingCounter++;

            try {
                result = await this._vm.$api.getOrders(config);
            }
            finally {
                state.loadingCounter--;
            }

            let orders = [];
            let customers = [];

            result.records.forEach((order) => {
                if(order.customer) {
                    let filteredOrder = normalizeRelations(order, ['customer']);
                    orders.push(filteredOrder);
                    customers.push(order.customer);
                }
                else {
                    orders.push(order);
                }
            });

            commit('set', { orders: orders });

            if(customers.length > 0) {
                commit('customer/set', { customers: customers }, { root: true });
            }

            return {
                total: result.count,
                ids: result.records.map(order => order.id)
            };
        },

        async fetchMany({ commit, state }, config) {
            state.loadingCounter++;

            let ids = config.ids;

            let result = await this._vm.$api.get('/api/orders/' + ids.join(','), {
            });

            let orders = [];
            let customers = [];

            if(Array.isArray(result)) {
                result.forEach((order) => {
                    if(order.customer) {
                        let filteredOrder = normalizeRelations(order, ['customer']);
                        orders.push(filteredOrder);
                        customers.push(order.customer);
                    }
                    else {
                        orders.push(order);
                    }
                });
            }
            else {
                if(result.customer) {
                    let filteredOrder = normalizeRelations(result, ['customer']);
                    orders.push(filteredOrder);
                    customers.push(result.customer);
                }
                else {
                    orders.push(result);
                }
            }

            commit('set', { orders: orders });

            if(customers.length > 0) {
                commit('customer/set', { customers: customers }, { root: true });
            }

            state.loadingCounter--;
        },

        async fetchOne({ commit, state }, config) {
            state.loadingCounter++;

            let id = config.id;
            let fields = config.fields;

            let result = await this._vm.$api.getOrder(id, fields);

            commit('set', {
                orders: [ normalizeRelations(result, [ 'customer' ]) ]
            });

            commit('customer/set', {
                customers: [ result.customer ]
            }, { root: true });

            state.loadingCounter--;
        },

        async create({ commit, state }, data) {
            state.loadingCounter++;

            try {
                let result = await this._vm.$api._post('/api/orders', data);

                commit('set', {
                    orders: [ normalizeRelations(result, [ 'customer' ]) ]
                });

                commit('customer/set', {
                    customers: [ result.customer ]
                }, { root: true });

                return result.id;
            }
            finally {
                 state.loadingCounter--;
            }
        },

        addPayment({ commit, state }, { order, payment }) {
            return this._vm.$api.postOrderPayment(order.id, payment).then((result) => {
                commit('addPayment', { order: order, payment: result });
            });
        },

        fetchPayments({ commit, state }, { order }) {
            state.loadingCounter++;

            return this._vm.$api.getOrderPayments(order.id).then((result) => {
                commit('setPayments', { order: order, payments: result });
            }).finally(() => state.loadingCounter--);
        },

        async fetchShipments({ commit, state }, { order }) {
            state.loadingCounter++;

            let result = await this._vm.$api.get('/api/shipments', {
                orderId: order.id
            });

            commit('setShipping', { order: order, shipment: result.records[0] });
            commit('setShipments', { order: order, shipments: result.records });
        },

        addComment({ commit, state }, { order, comment }) {
            return this._vm.$api.postOrderComment(order.id, { comment: comment }).then((result) => {
                commit('addComment', { order: order, comment: result });
            });
        },

        fetchComments({ commit, state }, { order }) {
            state.loadingCounter++;

            return this._vm.$api.getOrderComments(order.id).then((result) => {
               commit('setComments', { order: order, comments: result });
            }).finally(() => state.loadingCounter--);
        },

        async fetchActivity({ commit, state }, { order }) {
            state.loadingCounter++;

            try {
                let result = await this._vm.$api.getOrderActivity(order.id);
                commit('setActivity', { order: order, activity: result });
            }
            finally {
                state.loadingCounter--;
            }
        },

        async updateDelivery({ commit, state }, { order, address, date, start, end, vehicleFeatures }) {
            let result = await this._vm.$api.patch('/api/orders/' + order.id, {
                address: address.id,
                deliveryDate: date,
                deliveryStartTime: start,
                deliveryEndTime: end,
                vehicleFeatures: vehicleFeatures
            });

            commit('set', {
                orders: [ normalizeRelations(result, [ 'customer' ]) ]
            });
        },

        async cancel({ commit, state }, { order }) {
            let result = await this._vm.$api.deleteOrder(order.id);
            commit('set', {
                orders: [ normalizeRelations(result, [ 'customer' ]) ]
            });
        },

        async lock({ commit, state }, { order }) {
            state.isLoading = true;

            try {
                let result = await this._vm.$api.lockOrder(order.id);
                if(result) {
                    commit('set', {
                        orders: [ normalizeRelations(result, [ 'customer' ]) ]
                    });
                }
            }
            finally {
                state.isLoading = false;
            }
        },

        async generateInvoice({ commit, state }, { order }) {
            state.isLoading = true;

            let id = null;

            try {
                let result = await this._vm.$api.postInvoice({ order: order.id });
                if(result.id) {
                    commit('set', {
                        orders: [
                            {
                                id: order.id,
                                invoice: result
                            }
                        ]
                    });

                    id = result.id;
                }
            }
            finally {
                state.isLoading = false;
            }

            return id;
        }
    }
};
