import Vue from 'vue';

export default {
    namespaced: true,

    state: {
        isLoading: false,
        accounts: [],
        stats: {
            total: 0
        }
    },

    getters: {
        isLoading: (state) => () => {
            return state.isLoading;
        },

        getAll: (state) => () => {
            return state.accounts;
        },

        getById: (state) => (id) => {
            let found = state.accounts.find(item => item.id === id);
            if(!found) {
                state.accounts.push({ id: id });
            }

            return state.accounts.find(item => item.id === id);
        },

        getOwned: (state, getters, rootState, rootGetters) => () => {
            let userId = rootGetters['user/get'].id;
            let isAdmin = rootGetters['user/hasRole']('ROLE_ADMIN');

            if(isAdmin) {
                return state.accounts;
            }
            else {
                return state.accounts.filter(ca => ca.owner.id === userId);
            }
        },

        getStats: (state) => () => {
            return state.stats;
        }
    },

    mutations: {
        setAll(state, { accounts }) {
            state.accounts = accounts;
        },

        setOne(state, { account }) {
            let found = state.accounts.find(c => c.id === account.id);

            if(found) {
                for(let key of Object.keys(account)) {
                    Vue.set(found, key, account[key]);
                }
            }
            else {
                state.accounts.push(account);
            }
        },

        setMovementsTotal(state, { account, total }) {
            Vue.set(account, 'movementsTotal', total);
        },

        setMovements(state, { account, movements }) {
            Vue.set(account, 'movements', movements);
        },

        setStats(state, { account, stats }) {
            state.stats = stats;
        },

        addMovements(state, { account, movements }) {
            if(account.movements) {
                for(let m of movements) {
                    let found = account.movements.find(c => c.id === m.id);
                    if(!found) {
                        account.movements.push(m);
                    }
                }
            }
            else {
                Vue.set(account, 'movements', movements);
            }
        }
    },

    actions: {
        fetchOne({ commit, state }, id) {
            state.isLoading = true;

            return this._vm.$api.getCashAccount(id).then((result) => {
                commit('setOne', { account: result });
            }).finally(() => {
                state.isLoading = false;
            });
        },

        fetchAll({ commit, state }) {
            state.isLoading = true;

            return this._vm.$api.getCashAccounts().then((result) => {
                commit('setAll', { accounts: result });
            }).finally(() => {
                state.isLoading = false;
            });
        },

        clearMovements({ commit, state }, { account }) {
            commit('setMovements', { account: account, movements: [] });
        },

        async fetchMovements({ commit, state }, { account, lastId, limit, filters }) {
            state.isLoading = true;

            let query = {
                lastId: lastId,
                limit: limit,
                ...filters
            };

            let result = await this._vm.$api.get('/api/cashAccounts/' + account.id + '/movements', query);
            commit('addMovements', { account: account, movements: result.records });
            commit('setMovementsTotal', { account: account, total: result.count });
            commit('setStats', { account: account, stats: result.stats });

            state.isLoading = false;
        },

        async create({ commit, state }, { data }) {
            let result = await this._vm.$api.postCashAccount(data);
            commit('setOne', { account: result });
        },

        patch({ commit, state }, { account, data }) {
            return this._vm.$api.patchCashAccount(account.id, data).then((result) => {
                commit('setOne', { account: result });
            });
        },

        addExpense({ commit, state }, { account, expense }) {
            state.isLoading = true;

            return this._vm.$api.addExpenseCashAccount(account.id, expense).then((result) => {

            }).finally(() => {
                state.isLoading = false;
            });
        },

        transfer({ commit, state }, { source, destination, amount }) {
            state.isLoading = true;

            return this._vm.$api.transferCashAccount(source.id, destination.id, amount).then((result) => {
                for(let a of result) {
                    commit('setOne', { account: a });
                }
            }).finally(() => {
                state.isLoading = false;
            });
        }
    }
};
