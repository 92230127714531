export default {
    namespaced: true,

    state: {
        isLoading: false,
        providers: []
    },

    getters: {
        getById: (state) => (id) => {
            return state.providers.find(item => item.id === id);
        },

        getAll: (state) => () => {
            return state.providers;
        },

        isLoading: (state) => () => {
            return state.isLoading;
        }
    },

    mutations: {
        setAll: (state, { providers }) => {
            state.providers = providers;
        },

        add: (state, provider) => {
            state.providers.push(provider);
        }
    },

    actions: {
        async fetchAll({ commit, state }) {
            let result = await this._vm.$api.get('/api/providers');
            commit('setAll', { providers: result });
        },

        async add({ commit, state }, data) {
            let result = this._vm.$api.post('/api/providers', data);
            commit('add', result);
        }
    }
};
