import Vue from 'vue';

export default {
    namespaced: true,

    state: {
        zones: []
    },

    getters: {
        getById: (state) => (id) => {
            return state.zones.find(item => item.id === id);
        }
    },

    mutations: {
        add: (state, { zone }) => {
            state.zones.push(zone);
        },

        setActive: (state, { id, active }) => {
            let zone = state.zones.find(item => item.id === id);
            zone.isActive = active;
        },

        setData: (state, { id, data }) => {
            let zone = state.zones.find(item => item.id === id);
            Object.assign(zone, data);
        },

        setSchedules: (state, { zoneId, schedules }) => {
            let zone = state.zones.find(item => item.id === zoneId);

            if(!zone.schedules) {
                Vue.set(zone, 'schedules', []);
            }

            zone.schedules = schedules;
        },

        addSchedule: (state, { zoneId, schedule }) => {
            let zone = state.zones.find(item => item.id === zoneId);

            if(!zone.schedules) {
                Vue.set(zone, 'schedules', []);
            }

            zone.schedules.push(schedule);
        },

        deleteSchedule: (state, { zoneId, schedule }) => {
            let zone = state.zones.find(item => item.id === zoneId);

            for(let i = 0; i < zone.schedules.length; i++) {
                if(zone.schedules[i].id === schedule.id) {
                    zone.schedules.splice(i, 1);
                    break;
                }
            }
        }
    },

    actions: {
        fetchOne({ commit, state }, id) {
            return this._vm.$api.getDeliveryZone(id).then((result) => {
                commit('add', { zone: result });
            });
        },

        setActive({ commit, state }, { id, active }) {
            return this._vm.$api.putDeliveryZoneActive(id, active).then((result) => {
                commit('setActive', { id: id, active: result.isActive });
            });
        },

        patch({ commit, state }, { id, data }) {
            return this._vm.$api.patchDeliveryZone(id, data).then((result) => {
                commit('setData', { id: id, data: result });
            });
        },

        fetchSchedules({ commit, state }, id) {
            return this._vm.$api.getDeliveryZoneSchedule(id).then((result) => {
                commit('setSchedules', { zoneId: id, schedules: result });
            });
        },

        addSchedule({ commit, state }, { zoneId, schedule }) {
            return this._vm.$api.postDeliveryZoneSchedule(zoneId, schedule).then((result) => {
                commit('addSchedule', { zoneId: zoneId, schedule: result });
            });
        },

        deleteSchedule({ commit, state }, { zoneId, schedule }) {
            return this._vm.$api.deleteDeliveryZoneSchedule(zoneId, schedule.id).then((result) => {
                commit('deleteSchedule', { zoneId: zoneId, schedule: schedule });
            });
        }
    }
};
