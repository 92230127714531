import Vue from 'vue';

export default {
    namespaced: true,

    state: {
        isLoading: false,
        features: []
    },

    getters: {
        isLoading: (state) => () => {
            return state.isLoading;
        },

        getAll: (state) => () => {
            return state.features;
        },

        getById: (state) => (id) => {
            return state.features.find(item => item.id === id);
        }
    },

    mutations: {
        set: (state, { features }) => {
            for(let feature of features) {
                let found = state.features.find(v => v.id === feature.id);

                if(found) {
                    for(let key of Object.keys(feature)) {
                        Vue.set(found, key, feature[key]);
                    }
                }
                else {
                    state.features.push(feature);
                }
            }
        }
    },

    actions: {
        async fetchAll({ commit, state }) {
            state.isLoading = true;

            try {
                let result = await this._vm.$api.get('/api/vehicle_features');
                commit('set', { features: result });
            }
            finally {
                state.isLoading = false;
            }
        },

        async create({ commit, state }, data) {

        },

        async delete({ commit, state }, { feature }) {

        }
    }
};
