import Vue from 'vue';
import { normalizeRelations, resolveRelations } from './helpers';

export default {
    namespaced: true,

    state: {
        isLoading: false,
        customers: []
    },

    getters: {
        isLoading: (state) => () => {
            return state.isLoading;
        },

        getAll: (state) => () => {
            return state.customers;
        },

        getById: (state, getters, rootState, rootGetters) => (id) => {
            let customer = state.customers.find(item => item.id === id);

            return customer;
        }
    },

    mutations: {
        set(state, { customers }) {
            for(let customer of customers) {
                let found = state.customers.find(c => c.id === customer.id);

                if(found) {
                    for(let key of Object.keys(customer)) {
                        Vue.set(found, key, customer[key]);
                    }
                }
                else {
                    if(!customer.orders) {
                        customer.orders = [];
                    }

                    if(!customer.shippingAddresses) {
                        customer.shippingAddresses = [];
                    }

                    if(!customer.billingAddresses) {
                        customer.billingAddresses = [];
                    }

                    state.customers.push(customer);
                }
            }
        },

        remove(state, { customer }) {
            let found = state.customers.find(c => c.id === customer.id);

            if(found) {
                const idx = state.customers.indexOf(found);
                if(idx !== -1) {
                    state.customers.splice(idx, 1);
                }
            }
        },

        setOrders(state, { customer, orders }) {
            let found = state.customers.find(c => c.id === customer.id);

            if(found) {
                Vue.set(found, 'orders', orders);
            }
        },

        setOrdersTotal(state, { customer, total }) {
            let found = state.customers.find(c => c.id === customer.id);

            if(found) {
                Vue.set(found, 'ordersTotal', total);
            }
        },

        setShippingAddresses(state, { customer, addresses }) {
            let found = state.customers.find(c => c.id === customer.id);

            if(found) {
                found.shippingAddresses = addresses;
            }
        },

        addShippingAddress(state, { customer, address }) {
            let found = state.customers.find(c => c.id === customer.id);

            if(found) {
                found.shippingAddresses.push(address);
            }
        },

        removeShippingAddress(state, { customer, address }) {
            let found = state.customers.find(c => c.id === customer.id);

            if(found) {
                if(found.shippingAddresses) {
                    const idx = found.shippingAddresses.indexOf(address);
                    if(idx !== -1) {
                        found.shippingAddresses.splice(idx, 1);
                    }
                }
            }
        },

        setBillingAddresses(state, { customer, addresses }) {
            let found = state.customers.find(c => c.id === customer.id);

            if(found) {
                found.billingAddresses = addresses;
            }
        },

        addBillingAddress(state, { customer, address }) {
            let foundCustomer = state.customers.find(c => c.id === customer.id);
            if(foundCustomer) {
                if(!foundCustomer.billingAddresses) {
                    Vue.set(foundCustomer, 'billingAddresses', [ address ]);
                }
                else {
                    let found = foundCustomer.billingAddresses.find(a => a.id === address.id);
                    if(found) {
                        for(let key of Object.keys(address)) {
                            Vue.set(found, key, address[key]);
                        }
                    }
                    else {
                        foundCustomer.billingAddresses.push(address);
                    }
                }
            }
        },

        removeBillingAddress(state, { customer, address }) {
            let found = state.customers.find(c => c.id === customer.id);

            if(found) {
                if(found.billingAddresses) {
                    const idx = found.billingAddresses.indexOf(address);
                    if(idx !== -1) {
                        found.billingAddresses.splice(idx, 1);
                    }
                }
            }
        }
    },

    actions: {
        async fetchOne({ commit, state }, id) {
            state.isLoading = true;

            try {
                let result = await this._vm.$api.get('/api/customers/' + id);
                commit('set', { customers: [ result ] });
            }
            finally {
                state.isLoading = false;
            }
        },

        async fetchAll({ commit, state }, filters) {
            state.isLoading = true;

            try {
                let result = await this._vm.$api.get('/api/customers', filters);
                commit('set', { customers: result.records });

                return {
                    total: result.count,
                    ids: result.records.map(customer => customer.id)
                };
            }
            finally {
                state.isLoading = false;
            }
        },

        async fetchDebt({ commit, state }, id) {
            state.isLoading = true;

            try {
                let result = await this._vm.$api.get('/api/customers/' + id + '/debt');

                let customer = {
                    id: id,
                    debt: result.debt
                };

                commit('set', { customers: [ customer ] });
            }
            finally {
                state.isLoading = false;
            }
        },

        async create({ commit, state }, { customer }) {
            state.isLoading = true;

            try {
                let result = await this._vm.$api.post('/api/customers', customer);
                commit('set', { customers: [ result ] });

                return result.id;
            }
            finally {
                state.isLoading = false;
            }
        },

        async patch({ commit, state }, { customer, data }) {
            state.isLoading = true;

            try {
                let result = await this._vm.$api.patch('/api/customers/' + customer.id, data);

                commit('set', { customers: [result] });
            }
            finally {
                state.isLoading = false;
            }
        },

        async delete({ commit, state }, { customer }) {
            state.isLoading = true;

            try {
                await this._vm.$api.delete('/api/customers/' + customer.id);

                commit('remove', { customer: customer });
            }
            finally {
                state.isLoading = false;
            }
        },

        async fetchOrders({ commit, state }, { customer, offset, limit }) {
            state.isLoading = true;

            try {
                let result = await this._vm.$api.get('/api/customers/' + customer.id + '/orders', {
                    offset: offset,
                    limit: limit
                });

                commit('setOrdersTotal', { customer: customer, total: result.count });
                commit('setOrders', { customer: customer, orders: result.records.map(order => order.id) });
                commit('order/set', { orders: result.records }, { root: true });
            }
            finally {
                state.isLoading = false;
            }
        },

        async fetchShippingAddresses({ commit, state }, { customer }) {
            let addresses = await this._vm.$api.get('/api/customers/' + customer.id + '/shippingAddresses');
            commit('setShippingAddresses', { customer: customer, addresses: addresses || [] });
        },

        async addShippingAddress({ commit, state }, { customer, address }) {
            let response = await this._vm.$api.post('/api/customers/' + customer.id + '/shippingAddresses', address);
            commit('addShippingAddress', { customer: customer, address: response });
        },

        async removeShippingAddress({ commit, state }, { customer, address }) {
            await this._vm.$api.delete('/api/customers/' + customer.id + '/shippingAddresses/' + address.id);
            commit('removeShippingAddress', { customer: customer, address: address });
        },

        async fetchBillingAddresses({ commit, state }, { customer }) {
            let addresses = await this._vm.$api.get('/api/customers/' + customer.id + '/billingAddresses');
            commit('setBillingAddresses', { customer: customer, addresses: addresses || [] });
        },

        async addBillingAddress({ commit, state }, { customer, address }) {
            let response = await this._vm.$api.post('/api/customers/' + customer.id + '/billingAddresses', address);
            commit('addBillingAddress', { customer: customer, address: response });
        },

        async updateBillingAddress({ commit, state }, { customer, address, data }) {
            let response = await this._vm.$api.patch('/api/customers/' + customer.id + '/billingAddresses/' + address.id, data);
            commit('addBillingAddress', { customer: customer, address: response });
        },

        async removeBillingAddress({ commit, state }, { customer, address }) {
            await this._vm.$api.delete('/api/customers/' + customer.id + '/billingAddresses/' + address.id);
            commit('removeBillingAddress', { customer: customer, address: address });
        }
    }
};
