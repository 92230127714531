import Vue from 'vue';

export default {
    namespaced: true,

    state: {
        isLoading: false,
        stock: []
    },

    getters: {
        isLoading: (state) => () => {
            return state.isLoading;
        },

        getByProductId: (state) => (id) => {
            let found = state.stock.find(item => item.productId === id);
            if(found) {
                return found;
            }

            return null;
        },

        get: (state) => () => {
            return state.stock;
        }
    },

    mutations: {
        set: (state, { stocks }) => {
            for(let stock of stocks) {
                let found = state.stock.find(item => item.productId === stock.productId);

                if(found) {
                    for(let key of Object.keys(stock)) {
                        Vue.set(found, key, stock[key]);
                    }
                }
                else {
                    state.stock.push(stock);
                }
            }
        },

        setData: (state, { product, data }) => {
            let stockItem = state.stock.find(item => item.productId === product);
            stockItem.currentStock = data.currentStock;
        },

        setMovements: (state, { product, movements }) => {
            let stockItem = state.stock.find(item => item.productId === product);
            Vue.set(stockItem, 'movements', movements);
        }
    },

    actions: {
        fetchAll({ commit, state }) {
            state.isLoading = true;

            return this._vm.$api.getStockList().then((result) => {
                commit('set', { stocks: result });
            }).finally(() => {
                state.isLoading = false;
            });
        },

        fetchOne({ commit, state }, id) {
            state.isLoading = true;

            return this._vm.$api.getStock(id).then((result) => {
                commit('set', { stocks: [result] });
            }).finally(() => {
                state.isLoading = false;
            });
        },

        create({ commit, state }, { data }) {
            state.isLoading = true;

            return this._vm.$api.postStock(data).then((result) => {
                commit('set', { stocks: [ result ] });
            }).finally(() => {
                state.isLoading = false;
            });
        },

        patch({ commit, state }, { stock, data }) {
            state.isLoading = true;

            return this._vm.$api.patchStock(stock.productId, data).then((result) => {
                commit('set', { stocks: [ result ] });
            }).finally(() => {
                state.isLoading = false;
            });
        },

        fetchMovements({ commit, state }, product) {
            state.isLoading = true;

            return this._vm.$api.getStockMovements(product).then((result) => {
                commit('setMovements', { product: product, movements: result });
            }).finally(() => {
                state.isLoading = false;
            });
        },

        async addMovements({ commit, state }, movements) {
            state.isLoading = true;

            let result = await this._vm.$api.putStock(movements);

            commit('set', { stocks: result });

            state.isLoading = false;
        }
    }
};
