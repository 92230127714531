import Vue from 'vue';

export default {
    namespaced: true,

    state: {
        isLoading: false,
        isSaving: false,
        claims: [],
        total: 0
    },

    getters: {
        isLoading: (state) => {
            return state.isLoading;
        },

        isSaving: (state) => {
            return state.isSaving;
        },

        getAll: (state) => () => {
            return state.claims;
        },

        getById: (state) => (id) => {
            return state.claims.find(item => item.id === id);
        },

        getServerTotal: (state) => () => {
            return state.total;
        }
    },

    mutations: {
        reset: (state) => {
            state.total = 0;
            state.claims = [];
        },

        set: (state, { claims, total }) => {
            state.total = total;

            for(let claim of claims) {
                let found = state.claims.find(item => item.id === claim.id);

                if(found) {
                    for(let key of Object.keys(claim)) {
                        Vue.set(found, key, claim[key]);
                    }
                }
                else {
                    state.claims.push(claim);
                }
            }
        }
    },

    actions: {
        async fetch({ commit, state }, query) {
            state.isLoading = true;

            try {
                let result = await this._vm.$api.get('/api/claims', query);

                commit('reset');
                commit('set', {
                    claims: result.records,
                    total: result.count
                });

                return {
                    total: result.count,
                    ids: result.records.map(claim => claim.id)
                };
            }
            finally {
                state.isLoading = false;
            }
        },

        async fetchOne({ commit, state }, { id }) {
            state.isLoading = true;

            try {
                let result = await this._vm.$api.get('/api/claims/' + id);
                commit('set', { claims: [ result ] });
            }
            finally {
                state.isLoading = false;
            }
        },

        async addComment({ commit, state }, { claim, comment }) {
            let result = await this._vm.$api.post('/api/claims/' + claim.id + '/comments', {
                comment: comment
            });
            commit('set', { claims: [ result ] });
        },

        async create({ commit, state }, data) {
            state.isSaving = true;

            try {
                let result = await this._vm.$api.post('/api/claims', data);
                commit('set', { claims: [ result ] });

                return result;
            }
            finally {
                state.isSaving = false;
            }
        },

        async resolve({ commit, state }, { claim, comment }) {
            state.isSaving = true;

            try {
                let result = await this._vm.$api.patch('/api/claims/' + claim.id, {
                    state: 'resolved',
                    comment: comment
                });
                commit('set', { claims: [ result ] });
            }
            finally {
                state.isSaving = false;
            }
        },

        async assign({ commit, state }, { claim, employeeId }) {
            state.isSaving = true;

            try {
                let result = await this._vm.$api.patch('/api/claims/' + claim.id, {
                    assigned: employeeId
                });
                commit('set', { claims: [ result ] });
            }
            finally {
                state.isSaving = false;
            }
        }
    }
};
