import Vue from 'vue';
import moment from 'moment';

Vue.filter('toCurrency', function(value) {
    value = parseFloat(value);

    if(typeof value !== 'number') {
        return value;
    }

    let formatter = new Intl.NumberFormat('es-AR', {
        style: 'currency',
        currency: 'ARS',
        minimumFractionDigits: 2
    });

    return formatter.format(value);
});

Vue.filter('toPercentage', function(value) {
    value = parseFloat(value);

    if(typeof value !== 'number') {
        return value;
    }

    return '% ' + value.toFixed(2);
});

Vue.filter('toInitials', function(value) {
    let arr = value.split(' ');

    let accum = arr.reduce((a, v) => {
        return a + v.charAt(0);
    }, '');

    return accum;
});

Vue.filter('toLowercase', function(value) {
    return value.toLowerCase();
});

Vue.filter('capitalize', function(value, options) {
    const globalOptions = (this && this.capitalize) ? this.capitalize : {};
    options = options || globalOptions;
    const onlyFirstLetter = options.onlyFirstLetter != null ? options.onlyFirstLetter : false;

    if(!value && value !== 0) {
        return '';
    }

    if(onlyFirstLetter === true) {
        return value.toString().charAt(0).toUpperCase() + value.toString().slice(1);
    }
    else {
        value = value.toString().toLowerCase().split(' ');
        return value.map(function(item) {
            return item.charAt(0).toUpperCase() + item.slice(1);
        }).join(' ');
    }
});

Vue.filter('toDayName', function(value) {
    return moment().isoWeekday(value).format('dddd');
});

Vue.filter('toCDNPath', function(value) {
    if(value) {
        return process.env.VUE_APP_TALLO_CDN + '/' + value;
    }
    else {
        return require('@/assets/blank_img.png');
    }
});

Vue.filter('truncate', function(value, limit) {
    if(value.length > limit) {
        value = value.substring(0, (limit - 3)) + '...';
    }

    return value;
});
