import Vue from 'vue';

export default {
    namespaced: true,

    state: {
        loadingCounter: 0,
        isLoading: false,

        ids: [],
        shipments: []
    },

    getters: {
        isLoading: (state) => () => {
            return state.loadingCounter > 0;
        },

        getAll: (state, getters) => () => {
            return state.ids.map(id => getters.getById(id));
        },

        getById: (state) => (id) => {
            let shipment = state.shipments.find(item => item.id === id);
            if(!shipment) {
                shipment = { id: id };
                state.shipments.push(shipment);
            }

            return shipment;
        }
    },

    mutations: {
        set(state, { shipments }) {
            for(let shipment of shipments) {
                let found = state.shipments.find(o => o.id === shipment.id);

                if(found) {
                    for(let key of Object.keys(shipment)) {
                        Vue.set(found, key, shipment[key]);
                    }
                }
                else {
                    state.shipments.push(shipment);
                }
            }
        }
    },

    actions: {
        async fetchAll({ commit, state }, filters) {
            let result = null;
            state.loadingCounter++;

            try {
                result = await this._vm.$api.get('/api/shipments', filters);
                commit('set', { shipments: result.records });
            }
            finally {
                state.loadingCounter--;
            }

            return [
                ...result.records.map(s => s.id)
            ];
        },

        async dispatch({ commit, state }, { shipments }) {
            state.isLoading = true;

            try {
                let ids = shipments.map(s => s.id);
                let result = await this._vm.$api.put('/api/shipments/' + ids.join(','), {
                    state: 'state.transit'
                });

                commit('set', { shipments: result });
            }
            finally {
                state.isLoading = false;
            }
        },

        async pack({ commit, state }, { shipments }) {
            state.isLoading = true;

            try {
                let ids = shipments.map(s => s.id);
                let result = await this._vm.$api.put('/api/shipments/' + ids.join(','), {
                    state: 'state.packed'
                });

                commit('set', { shipments: result });
            }
            finally {
                state.isLoading = false;
            }
        },

        async deliver({ commit, state }, { shipment }) {
            state.isLoading = true;

            try {
                let result = await this._vm.$api.put('/api/shipments/' + shipment.id, {
                    state: 'state.delivered'
                });

                commit('set', { shipments: result });
            }
            finally {
                state.isLoading = false;
            }
        },

        async return({ commit, state }, { shipment, reason }) {
            state.isLoading = true;

            try {
                let result = await this._vm.$api.put('/api/shipments/' + shipment.id, {
                    state: 'state.returned',
                    reason: reason
                });

                commit('set', { shipments: result });
            }
            finally {
                state.isLoading = false;
            }
        }
    }
};
