import Vue from 'vue';

export default {
    namespaced: true,

    state: {
        isLoading: false,
        isSaving: false,

        harvest: [],
        total: 0
    },

    getters: {
        isLoading: (state) => () => {
            return state.isLoading;
        },

        isSaving: (state) => () => {
            return state.isSaving;
        },

        getAll: (state) => () => {
            return state.harvest;
        },

        getTotal: (state) => () => {
            return state.total;
        }
    },

    mutations: {
        set(state, { harvest }) {
            state.harvest = harvest;
        }
    },

    actions: {
        async fetchAll({ commit, state }, filters) {
            state.isLoading = true;

            try {
                let result = await this._vm.$api.getHarvest(filters);

                commit('set', { harvest: result.records });
                state.total = result.count;
                return {
                    total: result.count
                };
            }
            finally {
                state.isLoading = false;
            }
        }
    }
};
