import Vue from 'vue';

export default {
    namespaced: true,

    state: {
        isLoading: false,
        isSaving: false,

        employees: [],
        total: 0
    },

    getters: {
        isLoading: (state) => () => {
            return state.isLoading;
        },

        isSaving: (state) => () => {
            return state.isSaving;
        },

        getAll: (state) => () => {
            return state.employees;
        },

        getById: (state) => (id) => {
            return state.employees.find(item => item.id === id);
        },

        getTotal: (state) => () => {
            return state.total;
        }
    },

    mutations: {
        set(state, { employees }) {
            for(let employee of employees) {
                let found = state.employees.find(c => c.id === employee.id);

                if(found) {
                    for(let key of Object.keys(employee)) {
                        Vue.set(found, key, employee[key]);
                    }
                }
                else {
                    state.employees.push(employee);
                }
            }
        }
    },

    actions: {
        fetchOne({ commit, state }, id) {
            state.isLoading = true;

            return this._vm.$api.getEmployee(id).then((result) => {
                commit('set', { employees: [ result ] });
            }).finally(() => {
                state.isLoading = false;
            });
        },

        fetchAll({ commit, state }, filters) {
            state.isLoading = true;

            return this._vm.$api.getEmployees(filters).then((result) => {
                commit('set', { employees: result.records });
                state.total = result.count;

                return {
                    total: result.count,
                    ids: result.records.map(employee => employee.id)
                };
            }).finally(() => {
                state.isLoading = false;
            });
        },

        create({ commit, state }, { data }) {
            state.isSaving = true;

            return this._vm.$api.createEmployee(data).then((result) => {
                commit('set', { employees: [ result ] });

                return {
                    id: result.id
                };
            }).finally(() => {
                state.isSaving = false;
            });
        },

        patch({ commit, state }, { employee, data }) {
            state.isSaving = true;

            return this._vm.$api.patchEmployee(employee.id, data).then((result) => {
                commit('set', { employees: [ result ] });

                return {
                    id: result.id
                };
            }).finally(() => {
                state.isSaving = false;
            });
        }
    }
};
