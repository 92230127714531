import { normalizeRelations, resolveRelations } from './helpers';

export default {
    namespaced: true,

    state: {
        isLoading: false,
        routes: [],
        draft: true
    },

    getters: {
        isLoading: (state) => () => {
            return state.isLoading;
        },

        isDraft: (state) => () => {
            return state.draft;
        },

        getAll: (state, getters, rootState, rootGetters) => () => {
            return state.routes;
        }
    },

    mutations: {
        set(state, { routes, draft }) {
            state.routes = routes;
            state.draft = draft;
        }
    },

    actions: {
        async fetchAll({ commit, state }, config) {
            state.isLoading = true;

            try {
                let result = await this._vm.$api.getDeliveryRoutes(config);

                for(let r of result.routes) {
                    commit('shipments/set', { shipments: r.shipments }, { root: true });

                    let ids = r.shipments.map(s => s.id);
                    r.shipments = ids;
                }

                commit('set', {
                    routes: result.routes,
                    draft: result.draft
                });
            }
            finally {
                state.isLoading = false;
            }
        },

        clear({ commit, state }) {
            commit('set', { routes: [] });
        }
    }
};
