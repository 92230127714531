import Vue from 'vue';

export default {
    namespaced: true,

    state: {
        isLoading: false,
        products: []
    },

    getters: {
        getAll: (state) => () => {
            return state.products;
        },

        getById: (state) => (id) => {
            return state.products.find(item => item.id === id);
        },

        get: (state) => () => {
            return state.products;
        }
    },

    mutations: {
        set: (state, { products }) => {
            for(let product of products) {
                let found = state.products.find(item => item.id === product.id);

                if(found) {
                    for(let key of Object.keys(product)) {
                        Vue.set(found, key, product[key]);
                    }
                }
                else {
                    state.products.push(product);
                }
            }
        }
    },

    actions: {
        fetchAll({ commit, state }) {
            state.isLoading = true;

            return this._vm.$api.getProducts().then((result) => {
                commit('set', { products: result.records });

                return {
                    total: result.count,
                    ids: result.records.map(order => order.id)
                };
            }).finally(() => {
                state.isLoading = false;
            });
        },

        async create({ commit, state }, { data }) {
            state.isLoading = true;

            try {
                let result = await this._vm.$api.postProduct(data);

                if(data.thumbnail) {
                    await this._vm.$api.postProductThumbnail(result.id, data.thumbnail);
                }

                commit('set', { products: [ result ] });

                return result;
            }
            finally {
                state.isLoading = false;
            }
        },

        async patch({ commit, state }, { product, data }) {
            state.isLoading = true;

            try {
                if(data.thumbnail) {
                    await this._vm.$api.postProductThumbnail(product.id, data.thumbnail);
                }

                let result = await this._vm.$api.patchProduct(product.id, data);

                commit('set', { products: [ result ] });
            }
            finally {
                state.isLoading = false;
            }
        }
    }
};
