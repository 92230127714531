import Vue from 'vue';

export default {
    namespaced: true,

    state: {
        cart: null
    },

    getters: {
        get: (state) => () => {
            return state.cart;
        }
    },

    mutations: {
        set(state, { cart }) {
            if(!state.cart) {
                state.cart = cart;
            }
            else {
                for(let key of Object.keys(cart)) {
                    Vue.set(state.cart, key, cart[key]);
                }
            }
        },

        verify(state, verified) {
            state.verified = verified;
        }
    },

    actions: {
        create({ commit }) {
            return this._vm.$api.cartCreate().then((result) => {
                commit('set', { cart: result });

                return result.id;
            });
        },

        async add({ commit }, { cart, item }) {
            let result = await this._vm.$api.cartAdd(cart.id, item.product, item.quantity);
            commit('set', { cart: result });
        },

        async remove({ commit }, { cart, item }) {
            let result = await this._vm.$api.cartRemove(cart.id, item.product, item.quantity);
            commit('set', { cart: result });
        },

        setCustomer({ commit }, { cart, customer }) {
            return this._vm.$api.patch('/api/carts/' + cart.id, {
                customer: customer.id
            }).then((result) => {
                commit('set', { cart: result });
            });
        },

        async setOrderType({ commit }, { cart, orderType }) {
            let result = await this._vm.$api.patch('/api/carts/' + cart.id, {
                orderType: orderType
            });

            commit('set', { cart: result });
        },

        setShippingAddress({ commit }, { cart, address }) {
            return this._vm.$api.patch('/api/carts/' + cart.id, {
                shippingAddress: address.id
            }).then((result) => {
                commit('set', { cart: result });
            });
        },

        async setDeliverySchedule({ commit }, { cart, schedule }) {
            let result = null;

            if(schedule.id === -1) {
                result = await this._vm.$api.patch('/api/carts/' + cart.id, {
                    schedule: schedule
                });
            }
            else {
                result = await this._vm.$api.patch('/api/carts/' + cart.id, {
                    schedule: schedule.id
                });
            }

            commit('set', { cart: result });
        },

        setBillingAddress({ commit }, { cart, address }) {
            return this._vm.$api.patch('/api/carts/' + cart.id, {
                billingAddress: address.id
            }).then((result) => {
                commit('set', { cart: result });
            });
        }
    }
};
