export default {
    namespaced: true,

    state: {
        isLoading: false,
        parameters: [],
        loaded: false
    },

    getters: {
        isLoading: (state) => () => {
            return state.isLoading;
        },

        getAll: (state) => () => {
            return state.parameters;
        },

        get: (state) => (key) => {
            return state.parameters[key];
        }
    },

    mutations: {
        set: (state, { parameters }) => {
            state.parameters = parameters;
        }
    },

    actions: {
        fetch({ commit, state }) {
            // TODO: Maybe invalidate after 5 minutes or something?
            if(state.parameters && state.loaded) {
                return;
            }

            state.isLoading = true;

            return this._vm.$api.getParameters().then((result) => {
                commit('set', { parameters: result });
            }).finally(() => {
                state.isLoading = false;
                state.loaded = true;
            });
        },

        update({ commit, state }, parameters) {
            state.isLoading = true;

            return this._vm.$api.putParameters(parameters).then((result) => {
                commit('set', { parameters: result });
            }).finally(() => {
                state.isLoading = false;
            });
        }
    }
};
