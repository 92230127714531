import router from '../router';
import qs from 'qs';
import store from '../store';

export default class TalloAPIPlugin {
    static install(Vue/* , options */) {
        Vue.prototype.$api = new Vue({
            data: function() {
                return {
                    user: null,
                    baseURI: process.env.VUE_APP_TALLO_API_URL,
                    opts: {
                        headers: {
                            'Content-Type': 'application/json',
                            Accept: 'application/json'
                        }
                    }
                };
            },

            methods: {
                getParameters() {
                    return this._get('/api/config');
                },

                putParameters(params) {
                    return this._put('/api/config', params);
                },

                login(user, pass) {
                    function isEmail(email) {
                        // TODO: Disabling this lint until i have time to read this regex and understand it
                        // eslint-disable-next-line
                        const re = /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                        return re.test(String(email).toLowerCase());
                    }

                    let body = {
                        password: pass
                    };

                    if(isEmail(user)) {
                        body.email = user;
                    }
                    else {
                        body.username = user;
                    }

                    return this._post('/api/auth/login', body);
                },

                register(data) {
                    return this._post('/api/users', data);
                },

                verifyRegistration(email, code) {
                    return this._post('/api/users/verify', {
                        email: email,
                        code: code
                    });
                },

                requestNewPassword(email) {
                    return this._post('/api/auth/forgot', {
                        email: email
                    });
                },

                setNewPassword(email, password, code) {
                    return this._post('/api/auth/reset_password', {
                        email: email,
                        code: code,
                        password: password
                    });
                },

                getSessionCart() {
                    return this._get('/api/carts');
                },

                cartCreate() {
                    return this._post('/api/carts');
                },

                cartAdd(cart, product, quantity) {
                    return this._put('/api/carts/' + cart, {
                        product: product,
                        quantity: quantity
                    });
                },

                cartRemove(cart, product, quantity) {
                    return this._put('/api/carts/' + cart, {
                        product: product,
                        quantity: -quantity
                    });
                },

                getUsers(config) {
                    let query = Object.keys(config)
                        .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(config[k]))
                        .join('&');

                    return this._get('/api/users' + (query !== '' ? ('?' + query) : ''));
                },

                getUser(id) {
                    return this._get('/api/users/' + id);
                },

                getUserNotifications(id) {
                    return this._get('/api/users/' + id + '/notifications');
                },

                patchUserNotificationsRead(id, ids) {
                    return this._patch('/api/users/' + id + '/notifications/' + ids.join(','));
                },

                addUser(data) {
                    return this._post('/api/users', data);
                },

                getEmployees(filters) {
                    let query = qs.stringify(filters, { encodeValuesOnly: true });
                    return this._get('/api/employees' + (query !== '' ? ('?' + query) : ''));
                },

                getEmployee(id) {
                    return this._get('/api/employees/' + id);
                },

                createEmployee(data) {
                    return this._post('/api/employees', data);
                },

                patchEmployee(id, data) {
                    return this._patch('/api/employees/' + id, data);
                },

                getCustomerPaymentMethods(id) {
                    return this._get('/api/customers/' + id + '/paymentMethods');
                },

                getCategories() {
                    return this._get('/api/categories');
                },

                getProducts() {
                    return this._get('/api/products');
                },

                getProduct(id) {
                    return this._get('/api/products/' + id);
                },

                getRecommendedProducts() {
                    return this._get('/api/products?recommended=true');
                },

                postProduct(data) {
                    return this._post('/api/products', data);
                },

                patchProduct(id, data) {
                    return this._patch('/api/products/' + id, data);
                },

                postProductThumbnail(id, file) {
                    let formData = new FormData();
                    formData.append('thumbnail', file, file.name);

                    return this._post('/api/products/' + id + '/thumbnail', formData);
                },

                /**
                 * Delivery Zones
                 */

                getZones() {
                    return this._get('/api/zones');
                },

                getZonesByLocation(location) {
                    return this._get('/api/zones?lng=' + location.lng + '&lat=' + location.lat);
                },

                getDeliveryZoneSchedule(id) {
                    return this._get('/api/zones/' + id + '/schedules');
                },

                postDeliveryZoneSchedule(id, payload) {
                    return this._post('/api/zones/' + id + '/schedules', payload);
                },

                putDeliveryZoneActive(id, active) {
                    return this._put('/api/zones/' + id + '/active', {
                        active: active
                    });
                },

                patchDeliveryZone(id, data) {
                    return this._patch('/api/zones/' + id, data);
                },

                deleteDeliveryZoneSchedule(zoneId, id) {
                    return this._delete('/api/zones/' + zoneId + '/schedules/' + id);
                },

                getOrders(filters) {
                    let query = qs.stringify(filters, { encodeValuesOnly: true });

                    return this._get('/api/orders?' + query);
                },

                getOrder(id, fields = []) {
                    let query = '';
                    if(fields.length > 0) {
                        query += 'fields=' + fields.join(',');
                    }

                    return this._get('/api/orders/' + id + '?' + query);
                },

                getOrderActivity(id) {
                    return this._get('/api/orders/' + id + '/activity');
                },

                patchOrder(orderId, order) {
                    return this._patch('/api/orders/' + orderId, order);
                },

                deleteOrder(orderId) {
                    return this._delete('/api/orders/' + orderId);
                },

                putDeliverOrder(id, data) {
                    return this._put('/api/orders/' + id + '/delivery', data);
                },

                getOrderComments(id) {
                    return this._get('/api/orders/' + id + '/comments');
                },

                postOrderComment(id, data) {
                    return this._post('/api/orders/' + id + '/comments', data);
                },

                postInvoice(data) {
                    return this._post('/api/invoices', data);
                },

                /**
                 * Get harvest for date
                 *
                 * @param date
                 * @returns {*}
                 */
                getHarvest(filters) {
                    let query = qs.stringify(filters, { encodeValuesOnly: true });
                    return this._get('/api/harvest?' + query);
                },

                getDeliveryZones(filters) {
                    let query = qs.stringify(filters, { encodeValuesOnly: true });

                    return this._get('/api/zones?' + query);
                },

                getDeliveryZone(id) {
                    return this._get('/api/zones/' + id);
                },

                getDeliveryRoutes(filters) {
                    let query = qs.stringify(filters, { encodeValuesOnly: true });

                    return this._get('/api/shipment_routes?' + query);
                },

                /**
                 * Debtors
                 */

                getDebtors(filters) {
                    let query = qs.stringify(filters, { encodeValuesOnly: true });

                    return this._get('/api/debtors?' + query);
                },

                /** Stock */
                getStock(id) {
                    return this._get('/api/stock/' + id);
                },

                getStockList() {
                    return this._get('/api/stock');
                },

                getStockMovements(id) {
                    return this._get('/api/stock/' + id + '/movements');
                },

                postStock(data) {
                    return this._post('/api/stock', data);
                },

                patchStock(id, data) {
                    return this._patch('/api/stock/' + id, data);
                },

                putStock(data) {
                    return this._put('/api/stock', data);
                },

                /** Order Payments */
                getOrderPayments(orderId) {
                    return this._get('/api/orders/' + orderId + '/payments');
                },

                postOrderPayment(orderId, payment) {
                    return this._post('/api/orders/' + orderId + '/payments', payment);
                },

                /** Cash Accounts */
                getCashAccounts() {
                    return this._get('/api/cashAccounts');
                },

                getCashAccount(id) {
                    return this._get('/api/cashAccounts/' + id);
                },

                postCashAccount(data) {
                    return this._post('/api/cashAccounts', data);
                },

                patchCashAccount(id, data) {
                    return this._patch('/api/cashAccounts/' + id, data);
                },

                transferCashAccount(source, destination, amount) {
                    return this._put('/api/cashAccounts', {
                        source: source,
                        destination: destination,
                        amount: amount
                    });
                },

                addExpenseCashAccount(id, expense) {
                    let formData = new FormData();

                    if(expense.file) {
                        formData.append('file', expense.file, expense.file.name);
                    }

                    formData.append('concept', expense.concept);
                    formData.append('amount', expense.amount);

                    return this._post('/api/cashAccounts/' + id + '/expenses', formData);
                },

                get(path, query) {
                    let queryString = qs.stringify(query, { encodeValuesOnly: true });
                    return this._get(path + '?' + queryString);
                },

                patch(path, data) {
                    return this._patch(path, data);
                },

                post(path, data) {
                    return this._post(path, data);
                },

                delete(path, data) {
                    return this._delete(path);
                },

                put(path, data) {
                    return this._put(path, data);
                },

                getBlob(path, query) {
                    let queryString = qs.stringify(query, { encodeValuesOnly: true });

                    const token = store.getters['user/getToken']();
                    if(token) {
                        this.opts.headers.Authorization = `Bearer ${token}`;
                    }

                    let opts = { ...this.opts };
                    opts.responseType = 'blob';
                    opts.timeout = 600000;

                    return this.$axios.get(this.baseURI + path + '?' + queryString, opts).then(response => {
                        return response.data;
                    }).catch(error => {
                        if(error.response && error.response.status === 401) {
                            store.dispatch('user/logout');
                            location.reload(true);
                        }
                        else if(error.response && error.response.status === 403) {
                            console.error('Forbidden action');
                        }
                        else if(error.response && error.response.data) {
                            throw error.response.data;
                        }
                        else {
                            throw error;
                        }
                    });
                },

                _get(path) {
                    const token = store.getters['user/getToken']();
                    if(token) {
                        this.opts.headers.Authorization = `Bearer ${token}`;
                    }

                    return this.$axios.get(this.baseURI + path, this.opts).then(response => {
                        return response.data;
                    }).catch(error => {
                        if(error.response && error.response.status === 401) {
                            store.dispatch('user/logout');
                            location.reload(true);
                        }
                        else if(error.response && error.response.status === 403) {
                            console.error('Forbidden action');
                        }
                        else if(error.response && error.response.data) {
                            throw error.response.data;
                        }
                        else {
                            throw error;
                        }
                    });
                },

                _post(path, data) {
                    const token = store.getters['user/getToken']();
                    if(token) {
                        this.opts.headers.Authorization = `Bearer ${token}`;
                    }

                    return this.$axios.post(this.baseURI + path, data, this.opts).then(response => {
                        return response.data;
                    }).catch(error => {
                        if(error.response && error.response.status === 401) {
                            store.dispatch('user/logout');
                            location.reload(true);
                        }
                        else if(error.response && error.response.status === 403) {
                            console.error('Forbidden action');
                        }
                        else if(error.response && error.response.data) {
                            throw error.response.data;
                        }
                        else {
                            throw error;
                        }
                    });
                },

                _patch(path, data) {
                    const token = store.getters['user/getToken']();
                    if(token) {
                        this.opts.headers.Authorization = `Bearer ${token}`;
                    }

                    return this.$axios.patch(this.baseURI + path, data, this.opts).then(response => {
                        return response.data;
                    }).catch(error => {
                        if(error.response && error.response.status === 401) {
                            store.dispatch('user/logout');
                            location.reload(true);
                        }
                        else if(error.response && error.response.status === 403) {
                            console.error('Forbidden action');
                        }
                        else if(error.response && error.response.data) {
                            throw error.response.data;
                        }
                        else {
                            throw error;
                        }
                    });
                },

                _put(path, data) {
                    const token = store.getters['user/getToken']();
                    if(token) {
                        this.opts.headers.Authorization = `Bearer ${token}`;
                    }

                    return this.$axios.put(this.baseURI + path, data, this.opts).then(response => {
                        return response.data;
                    }).catch(error => {
                        if(error.response && error.response.status === 401) {
                            store.dispatch('user/logout');
                            location.reload(true);
                        }
                        else if(error.response && error.response.status === 403) {
                            console.error('Forbidden action');
                        }
                        else if(error.response && error.response.data) {
                            throw error.response.data;
                        }
                        else {
                            throw error;
                        }
                    });
                },

                _delete(path, data) {
                    const token = store.getters['user/getToken']();
                    if(token) {
                        this.opts.headers.Authorization = `Bearer ${token}`;
                    }

                    let config = {};
                    Object.assign(config, this.opts);
                    Object.assign(config, {
                        data: data
                    });

                    return this.$axios.delete(this.baseURI + path, config).then(response => {
                        return response.data;
                    }).catch(error => {
                        if(error.response && error.response.status === 401) {
                            store.dispatch('user/logout');
                            location.reload(true);
                        }
                        else if(error.response && error.response.status === 403) {
                            console.error('Forbidden action');
                        }
                        else if(error.response && error.response.data) {
                            throw error.response.data;
                        }
                        else {
                            throw error;
                        }
                    });
                }
            }
        });
    }
}
