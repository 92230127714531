import Vue from 'vue';
import Router from 'vue-router';

// eslint-disable-next-line no-unused-vars
import { store } from './store';

import qs from 'qs';

Vue.use(Router);

let router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'dashboard',
            component: () => import(/* webpackChunkName: "dashboard" */ './pages/Dashboard/Dashboard.vue')
        },
        {
            path: '/login',
            name: 'login',
            component: () => import(/* webpackChunkName: "login" */ './pages/Login/Login.vue')
        },
        {
            path: '/logout',
            name: 'logout'
        },
        {
            path: '/orders',
            name: 'orderList',
            component: () => import(/* webpackChunkName: "orderList" */ './pages/Orders/OrderList/OrderList.vue')
        },
        {
            path: '/orders/new',
            name: 'orderCreate',
            component: () => import(/* webpackChunkName: "orderCreate" */ './pages/Orders/OrderCreate/OrderCreate.vue')
        },
        {
            path: '/orders/:id?',
            name: 'orderView',
            component: () => import(/* webpackChunkName: "orderView" */ './pages/Orders/OrderView/OrderView.vue')
        },
        {
            path: '/orders/:id/edit',
            name: 'orderEdit',
            component: () => import(/* webpackChunkName: "orderEdit" */ './pages/Orders/OrderEditContents.vue')
        },
        {
            path: '/orchard/harvest',
            name: 'harvestList',
            component: () => import(/* webpackChunkName: "harvestList" */ './pages/Orchard/Harvest/HarvestList.vue')
        },
        {
            path: '/shipping',
            name: 'shippingList',
            component: () => import(/* webpackChunkName: "shippingList" */ './pages/Shipping/ShippingList.vue')
        },
        {
            path: '/orchard/packing',
            name: 'packingList',
            component: () => import(/* webpackChunkName: "packingList" */ './pages/Orchard/Packing/PackingList.vue')
        },
        {
            path: '/dispatch',
            name: 'dispatchList',
            component: () => import(/* webpackChunkName: "dispatchList" */ './pages/Dispatch/DispatchList.vue')
        },
        {
            path: '/logistics/routes',
            name: 'deliveryRoutesList',
            component: () => import(/* webpackChunkName: "deliveryRoutesList" */ './pages/Logistics/Delivery/DeliveryRoutesList.vue')
        },
        {
            path: '/customers',
            name: 'customerList',
            component: () => import(/* webpackChunkName: "customerList" */ './pages/Customers/CustomerList/CustomerList.vue')
        },
        {
            path: '/customers/:id',
            name: 'customerView',
            component: () => import(/* webpackChunkName: "customerView" */ './pages/Customers/CustomerView/CustomerView.vue')
        },
        {
            path: '/products',
            name: 'productList',
            component: () => import(/* webpackChunkName: "productList" */ './pages/Products/ProductList/ProductList.vue')
        },
        {
            path: '/payments',
            name: 'paymentsList',
            component: () => import(/* webpackChunkName: "paymentsList" */ './pages/Payments/PaymentsList.vue')
        },
        {
            path: '/cash',
            name: 'cashAccountList',
            component: () => import(/* webpackChunkName: "cashAccountList" */ './pages/Billing/CashAccountList/CashAccountList.vue')
        },
        {
            path: '/billing/cash/:id',
            name: 'cashAccountMovementsList',
            component: () => import(/* webpackChunkName: "cashAccountMovementsList" */ './pages/Billing/CashAccountMovementsList/CashAccountMovementsList.vue')
        },
        {
            path: '/accounting/invoices',
            name: 'invoiceList',
            component: () => import(/* webpackChunkName: "accountingSalesList" */ './pages/Accounting/InvoiceList.vue')
        },
        {
            path: '/production/providers',
            name: 'providerList',
            component: () => import(/* webpackChunkName: "providerList" */ './pages/Production/ProviderList/ProviderList.vue')
        },
        {
            path: '/production/providers/create',
            name: 'providerCreate',
            component: () => import(/* webpackChunkName: "providerCreate" */ './pages/Production/ProviderCreate/ProviderCreate.vue')
        },
        {
            path: '/production/inventory',
            name: 'inventoryList',
            component: () => import(/* webpackChunkName: "inventoryList" */ './pages/Production/Inventory/InventoryList.vue')
        },
        {
            path: '/production/inventory_movements',
            name: 'inventoryMovementsList',
            component: () => import(/* webpackChunkName: "inventoryMovementsList" */ './pages/Production/Inventory/InventoryMovementsList.vue')
        },
        {
            path: '/production/processes',
            name: 'processList',
            component: () => import(/* webpackChunkName: "processList" */ './pages/Production/Process/ProcessList.vue')
        },
        {
            path: '/production/process',
            name: 'process',
            component: () => import(/* webpackChunkName: "process" */ './pages/Production/Process/Process.vue')
        },
        {
            path: '/admin/deliveryZones',
            name: 'deliveryZoneList',
            component: () => import(/* webpackChunkName: "deliveryZoneList" */ './pages/Admin/DeliveryZoneList/DeliveryZoneList.vue')
        },
        {
            path: '/admin/deliveryZones/:id',
            name: 'deliveryZoneView',
            component: () => import(/* webpackChunkName: "deliveryZoneView" */ './pages/Admin/DeliveryZoneView/DeliveryZoneView.vue')
        },
        {
            path: '/admin/employees',
            name: 'employeeList',
            component: () => import(/* webpackChunkName: "employeeList" */ './pages/Admin/EmployeeList/EmployeeList.vue')
        },
        {
            path: '/admin/vehicles',
            name: 'vehicleList',
            component: () => import(/* webpackChunkName: "vehicleList" */ './pages/Admin/Vehicles/VehicleList.vue')
        },
        {
            path: '/admin/vehicles/:id/schedule',
            name: 'adminVehicleSchedule',
            component: () => import(/* webpackChunkName: "adminVehicleSchedule" */ './pages/Admin/Vehicles/VehicleSchedule.vue')
        },
        {
            path: '/admin/holidays',
            name: 'holidayList',
            component: () => import(/* webpackChunkName: "holidayList" */ './pages/Admin/Holidays/HolidayList.vue')
        },
        {
            path: '/admin/categories',
            name: 'productCategoryList',
            component: () => import(/* webpackChunkName: "productCategoryList" */ './pages/Admin/ProductCategory/ProductCategoryList.vue')
        },
        {
            path: '/admin/users/:id',
            name: 'userView',
            component: () => import(/* webpackChunkName: "userView" */ './pages/Admin/UserView/UserView.vue')
        },
        {
            path: '/admin/parameters',
            name: 'parametersList',
            component: () => import(/* webpackChunkName: "parametersList" */ './pages/Admin/Parameters/ParameterList.vue')
        },
        {
            path: '/stats/orders/map',
            name: 'ordersHeatMap',
            component: () => import(/* webpackChunkName: "ordersHeatMap" */ './pages/Reports/OrdersHeatMap.vue')
        },
        {
            path: '/reports/oia',
            name: 'reportsOIA',
            component: () => import(/* webpackChunkName: "reportsOIA" */ './pages/Reports/OIAReport.vue')
        },
        {
            path: '/claims',
            name: 'claimList',
            component: () => import(/* webpackChunkName: "claimList" */ './pages/Support/ClaimList.vue')
        },
        {
            path: '/claims/:id',
            name: 'claimView',
            component: () => import(/* webpackChunkName: "claimView" */ './pages/Support/ClaimView.vue')
        }
    ],

    parseQuery(query) {
        return qs.parse(query);
    },

    stringifyQuery(query) {
        let r = qs.stringify(query, { encodeValuesOnly: true });
        return r ? ('?' + r) : '';
    }
});

router.beforeEach((to, from, next) => {
    if(!from) {
        router.app.isLoading = true;
    }

    router.app.$store.dispatch('parameters/fetch');

    if(!router.app.$store.getters['user/isAuthenticated']) {
        // Allow login page
        if(to.path === '/login') {
            next();
        }
        else {
            // Check if the route requires authentication and redirect the request to the login if so.
            next({
                path: '/login',
                query: { redirect: to.fullPath }
            });
        }
    }
    else {
        router.app.$store.dispatch('user/fetchNotifications');

        next();
    }
});

router.afterEach((to, from) => {
    router.app.isLoading = false;
});

export default router;
